import React, { useState } from 'react';
import axios from 'axios';
import BASE_URL from './apiConfig'; 


const DeleteUser = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const apiUrl = 'https://empty-tips-call.loca.lt/api/delete-user';
      const apiUrl = `${BASE_URL}/api/delete-user`;

      const response = await axios.post(apiUrl, { email });
      alert('User deleted successfully');
      console.log('Response:', response.data);
    } catch (error) {
      console.error('There was an error deleting the user!', error);
      if (error.response) {
        console.error('Server responded with status code:', error.response.status);
        console.error('Response data:', error.response.data);
        alert(`Failed to delete user: ${error.response.data}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('Failed to delete user: No response from server.');
      } else {
        console.error('Error setting up request:', error.message);
        alert(`Failed to delete user: ${error.message}`);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
    <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl"> {/* Enlarged and dynamic container */}
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Delete User</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Email Field */}
        <div className="relative">
          <input
            type="email"
            placeholder="User's Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-envelope text-gray-400"></i>
          </span>
        </div>

        <button
          type="submit"
          className="w-full bg-red-500 text-white py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Delete User
        </button>
      </form>
    </div>
    </div>
  );
};

export default DeleteUser;
