import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AddDeviceSet from './components/AddDeviceSet';
import ManageBots from './components/ManageBots';
import ChangeServiceID from './components/ChangeServiceID';
import DeleteUser from './components/DeleteUser';
import UpdateSounder from './components/updateSounder';
import RealTimeDataDisplay from './components/RealTimeDataDisplay';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import SignOut from './components/SignOut';
import UserProfile from './components/userprofile';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  return (
    <Router>
      <Navbar />
      <div className="container mx-auto p-6">
        <Routes>
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/signout"
            element={<SignOut />}
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-device-set"
            element={
              <PrivateRoute>
                <AddDeviceSet />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-bots"
            element={
              <PrivateRoute>
                <ManageBots />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-service-id"
            element={
              <PrivateRoute>
                <ChangeServiceID />
              </PrivateRoute>
            }
          />
          <Route
            path="/delete-user"
            element={
              <PrivateRoute>
                <DeleteUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-sounder"
            element={
              <PrivateRoute>
                <UpdateSounder />
              </PrivateRoute>
            }
          />
          <Route
            path="/device-records"
            element={
              <PrivateRoute>
                <RealTimeDataDisplay  />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-profile"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
