import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, googleProvider } from './firebase';
import { signInWithPopup, signInWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import googleLogo from '../assets/google.png'; 
import BASE_URL from './apiConfig'; 

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(`Checking sign-in methods for email: ${email}`);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // await validateDomainOnServer(userCredential.user.email);
      navigate('/');
    } catch (error) {
      console.error('Error with email/password sign-in:', error.message);
      alert(error.message);
      auth.signOut(); // Optional: Sign out if the domain validation fails
    }
  };

  // const handleGoogleSignIn = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     // await validateDomainOnServer(result.user.email);
  //     navigate('/ ');
  //   } catch (error) {
  //     console.error('Error with Google sign-in:', error.message);
  //     alert(error.message);
  //     auth.signOut(); // Optional: Sign out if the domain validation fails
  //   }
  // };


  const handleGoogleSignIn = async () => {
    try {
      // Sign in with Google
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
  
      console.log(`Google sign-in email: ${user.email}`);
  
      // Check if the user email is already registered in Firebase
      const signInMethods = await fetchSignInMethodsForEmail(auth, user.email);
      console.log(`Sign-in methods for email ${user.email}: ${signInMethods}`);
  
      if (signInMethods.length === 0) {
        // User is not registered; redirect to sign-up
        navigate('/signup');
      } else {
        // User is registered; redirect to home page
        navigate('/');
      }
    } catch (error) {
      console.error('Error with Google sign-in:', error.message);
      alert(error.message);
    }
  };


  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">Sign In</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <i className="fas fa-envelope text-gray-400"></i>
            </span>
          </div>
          <div className="relative">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <i className="fas fa-lock text-gray-400"></i>
            </span>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Sign In
          </button>
        </form>
        {/* <div className="mt-6 text-center">
          <p className="mb-4">or</p>
          <button
            onClick={handleGoogleSignIn}
            className="w-full bg-white border border-gray-300 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 flex justify-center items-center"
          >
            <img src={googleLogo} alt="Google" className="h-6 w-6 mr-2" />
            <span className="text-gray-700">Sign in with Google</span>
          </button>
        </div> */}
        <div className="mt-6 text-center">
          <p>
            Don't have an account?{' '}
            <Link to="/signup" className="text-blue-500">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;