// src/components/SignOut.js
import React, { useEffect } from 'react';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Sign out the user
    auth.signOut().then(() => {
      // Redirect to the sign-in page
      navigate('/signin');
    });
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <p>Signing you out...</p>
    </div>
  );
};

export default SignOut;
