import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UserProfile = ({ userId }) => {
    const [userActions, setUserActions] = useState([]);

    useEffect(() => {
        axios.get(`/api/user/actions?user_id=${userId}`)
            .then(response => setUserActions(response.data))
            .catch(error => console.error('Error fetching user actions:', error));
    }, [userId]);

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-4 text-center">User Profile</h2>
                <h2>User Profile</h2>
                <div className="history">
                    <h3>Action History</h3>
                    <ul>
                        {userActions.map((action, index) => (
                            <li key={index}>
                                <p>{action.action}</p>
                                <p>{new Date(action.timestamp).toLocaleString()}</p>
                                <p>{action.details}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
