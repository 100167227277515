import React, { useState, useEffect } from 'react';
import { WEBSOCKET_URL } from './apiConfig';  // Adjust the path as necessary

const RealTimeDataDisplay = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Connect to the WebSocket server
    // const socket = new WebSocket('wss://empty-tips-call.loca.lt/ws'); 

    const socket = new WebSocket(`${WEBSOCKET_URL}/ws`);

    // Listen for messages
    socket.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    };

    // Handle errors
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Real-Time Data Updates</h2>
        <ul className="space-y-4">
          {messages.map((message, index) => (
            <li key={index} className="p-4 bg-gray-50 rounded-lg shadow">
              {JSON.stringify(message, null, 2)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RealTimeDataDisplay;
