import React, { useState } from 'react';
import axios from 'axios';
import BASE_URL from './apiConfig'; 

const AddDeviceSet = () => {
  const [email, setEmail] = useState('');
  const [moUUID, setMoUUID] = useState('');
  const [botUUIDs, setBotUUIDs] = useState('');
  const [serviceID, setServiceID] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const apiUrl = 'https://empty-tips-call.loca.lt/api/add-device-set';
      const apiUrl = `${BASE_URL}/api/add-device-set`;
      const response = await axios.post(apiUrl, {
        email,
        moUUID,
        botUUIDs,
        serviceID,
      });
      alert('Device set added successfully');
      console.log('Response:', response.data);
    } catch (error) {
      console.error('There was an error adding the device set!', error);
      if (error.response) {
        console.error('Server responded with status code:', error.response.status);
        console.error('Response data:', error.response.data);
        alert(`Failed to add device set: ${error.response.data}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('Failed to add device set: No response from server.');
      } else {
        console.error('Error setting up request:', error.message);
        alert(`Failed to add device set: ${error.message}`);
      }
    }
  };

  return (
    // <div className="container mx-auto p-6">
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
    <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl"> {/* Enlarged and dynamic container */}
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Add Device Set</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Email Field */}
        <div className="relative">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-envelope text-gray-400"></i>
          </span>
        </div>

        {/* MO UUID Field */}
        <div className="relative">
          <input
            type="text"
            placeholder="MO UUID"
            value={moUUID}
            onChange={(e) => setMoUUID(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-fingerprint text-gray-400"></i>
          </span>
        </div>

        {/* Bot UUIDs Field */}
        <div className="relative">
          <input
            type="text"
            placeholder="Bot UUIDs (comma-separated)"
            value={botUUIDs}
            onChange={(e) => setBotUUIDs(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-robot text-gray-400"></i>
          </span>
        </div>

        {/* Service ID Field */}
        <div className="relative">
          <input
            type="text"
            placeholder="Service ID"
            value={serviceID}
            onChange={(e) => setServiceID(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-cog text-gray-400"></i>
          </span>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Add Device Set
        </button>
      </form>
    </div>
    </div>
  );
};

export default AddDeviceSet;
