// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="bg-white p-4 shadow">
      <ul className="flex space-x-4">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/manage-bots">ESP32 specific</Link></li>
        <li><Link to="/add-device-set">Add Device Set</Link></li>
        <li><Link to="/change-service-id">Change Service ID</Link></li>
        <li><Link to="/update-sounder">Update Device</Link></li>
        <li><Link to="/delete-user">Delete User</Link></li>
        {/* <li><Link to="/user-profile">Profile</Link></li> */}
        {/* <li><Link to="/device-records">List 1</Link></li> */}
        <li><Link to="/signout">Sign Out</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
