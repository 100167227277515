import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, googleProvider } from './firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import BASE_URL from './apiConfig'; 


const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const validateDomainOnServer = async (email) => {
    const apiUrl = `${BASE_URL}/validate-domain`;

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Domain not allowed');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validateDomainOnServer(email);
      await createUserWithEmailAndPassword(auth, email, password);
      alert('Account created successfully.');
      navigate('/signin'); 
    } catch (error) {
      console.error('Error creating account:', error.message);
      alert(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">Sign Up</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <i className="fas fa-envelope text-gray-400"></i>
            </span>
          </div>
          <div className="relative">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <i className="fas fa-lock text-gray-400"></i>
            </span>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Sign Up
          </button>
        </form>
        {/* <div className="mt-6 text-center">
          <p className="mb-4">or</p>
          <button
            onClick={handleGoogleSignUp}
            className="w-full bg-white border border-gray-300 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 flex justify-center items-center"
          >
            <img src={googleLogo} alt="Google" className="h-6 w-6 mr-2" />
            <span className="text-gray-700">Sign up with Google</span>
          </button>
        </div> */}
        <div className="mt-6 text-center">
          <p>
            Already have an account?{' '}
            <Link to="/signin" className="text-blue-500">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;