import React, { useState } from 'react';
import axios from 'axios';

import BASE_URL from './apiConfig'; 


const ChangeServiceID = () => {
  const [oldServiceID, setOldServiceID] = useState('');
  const [newServiceID, setNewServiceID] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted'); // Debugging line
    try {
        // const apiUrl = 'https://gentle-clowns-lick.loca.lt/api/change-service-id';
        const apiUrl = `${BASE_URL}/api/change-service-id`;

        const response = await axios.post(apiUrl, {
          oldServiceID,
          newServiceID,
        });
        alert('Service ID changed successfully');
        console.log('Response:', response.data);
    } catch (error) {
        console.error('There was an error changing the service ID!', error);
    }
};

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
    <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl"> {/* Enlarged and dynamic container */}
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Change Service ID</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Old Service ID Field */}
        <div className="relative">
          <input
            type="text"
            placeholder="Old Service ID"
            value={oldServiceID}
            onChange={(e) => setOldServiceID(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-id-badge text-gray-400"></i>
          </span>
        </div>

        {/* New Service ID Field */}
        <div className="relative">
          <input
            type="text"
            placeholder="New Service ID"
            value={newServiceID}
            onChange={(e) => setNewServiceID(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-id-badge text-gray-400"></i>
          </span>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Change Service ID
        </button>
      </form>
    </div>
    </div>
  );
};

export default ChangeServiceID;
