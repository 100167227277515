import React, { useState } from 'react';
import axios from 'axios';

const ManageBots = () => {
  const [moId, setId] = useState('');
  const [botUUID, setBotUUID] = useState('');
  const [action, setAction] = useState('add');
  const [botName, setBotName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted'); // Debugging line
    try {
        const apiUrl = 'https://empty-tips-call.loca.lt/api/manage-bots';

        const response = await axios.post(apiUrl, {
            moId,
            botUUID,
            action,
            botName,
        });
        alert('Bot operation completed successfully');
        console.log('Response:', response.data);
    } catch (error) {
        console.error('There was an error managing the bot:', error);
    }
};



  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
    <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl"> {/* Enlarged and dynamic container */}
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">ESP32 specific</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* moId Field */}
        <div className="relative">
          <input
            type="setId"
            placeholder="MO setId"
            value={moId}
            onChange={(e) => setId(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-robot text-gray-400"></i>
          </span>
        </div>

        {/* Bot UUID Field */}
        <div className="relative">
          <input
            type="text"
            placeholder="SSID"
            value={botUUID}
            onChange={(e) => setBotUUID(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-wifi text-gray-400"></i>
          </span>
        </div>

        {/* Action Dropdown */}
        {/* <div className="relative">
          <select
            value={action}
            onChange={(e) => setAction(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="add">Add</option>
            <option value="remove">Remove</option>
          </select>
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-tasks text-gray-400"></i>
          </span>
        </div> */}

        {/* Password Field */}
        <div className="relative">
          <input
            type="text"
            placeholder="Password"
            value={botName}
            onChange={(e) => setBotName(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i className="fas fa-signature text-gray-400"></i>
          </span>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit
        </button>
      </form>
    </div>
    </div>
  );
};

export default ManageBots;
