// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBy8g3sSY1VNSsIsly7XfHm14sy035TlAQ",

    authDomain: "rds-web-app-6d3fd.firebaseapp.com",
  
    projectId: "rds-web-app-6d3fd",
  
    storageBucket: "rds-web-app-6d3fd.appspot.com",
  
    messagingSenderId: "7120482917",
  
    appId: "1:7120482917:web:e3593478cab6628d3bcc8c",
  
    measurementId: "G-X6MMNE5D8X"
  
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
