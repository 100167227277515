// src/components/Home.js
import React from 'react';

const Home = () => {
  return (
    <div className="container mx-auto p-6 text-center">
      <h1 className="text-3xl font-bold mb-6">Welcome to the RDS Management Interface</h1>
      <p className="text-lg mb-4">Select an option from the menu to begin managing your Hex sets.</p>
    </div>
  );
};

export default Home;
