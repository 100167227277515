import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import BASE_URL from './apiConfig'; 


const UpdateSounder = () => {
  // valid for 90 days, check python script --> hardcode for now because why not :P
  const [jwtToken, setJwtToken] = useState('');
  const [originId, setOriginId] = useState('');
  const [originIds, setOriginIds] = useState([]);
  const [queryResult, setQueryResult] = useState([]);

  const handleJwtTokenFetch = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/generate-token`);
      setJwtToken(response.data.token);
      console.log(response.data.token)
    } catch (error) {
      console.error('Error fetching new token', error);
    }
  };

  const handleQueryDB = async () => {
    try {
      const apiUrl = `${BASE_URL}/api/get-matching-ids-and-names?id=${originId}`;
      // const apiUrl = `http://nice-tires-talk.loca.lt/api/get-matching-ids-and-names?id=${originId}`;

      const response = await axios.get(apiUrl);

      setQueryResult(response.data);
    } catch (error) {
      console.error('Error querying the database', error);
      setQueryResult('Error fetching data');
    }
  };

  const handleIdChange = (index, newId) => {
    setQueryResult((prevResult) => {
      const updatedResult = [...prevResult];
      updatedResult[index].id = newId;
      return updatedResult;
    });
  };

  const handleNameChange = (index, newName) => {
    setQueryResult((prevResult) => {
      const updatedResult = [...prevResult];
      updatedResult[index].name = newName;
      return updatedResult;
    });
  };

  const handleMacAddressChange = (index, newMacAddress) => {
    setQueryResult((prevResult) => {
      const updatedResult = [...prevResult];
      updatedResult[index].macAddress = newMacAddress;
      return updatedResult;
    });
  };

  const handleInterfaceChange = (index, newInterface) => {
    setQueryResult((prevResult) => {
      const updatedResult = [...prevResult];
      updatedResult[index].interface = newInterface;
      return updatedResult;
    });
  };

  const handleDeleteRow = async (index) => {
    const botId = queryResult[index].id;

    if (!botId && !queryResult[index].name && !queryResult[index].macAddress && !queryResult[index].interface) {
      alert('Nothing to delete here, grow up!');
      return;
    }

    try {
      // const apiUrl = `https://nice-tires-talk.loca.lt/api/delete-bot`;
      const apiUrl = `${BASE_URL}/api/delete-bot`;

      await axios.post(apiUrl, { botId });

      // Remove the row from the UI after successful deletion
      setQueryResult((prevResult) => {
        const updatedResult = [...prevResult];
        updatedResult.splice(index, 1);
        return updatedResult;
      });

      alert('Bot deleted successfully');
    } catch (error) {
      console.error('Failed to delete bot:', error);
      alert('Failed to delete bot');
    }
  };

  const handleAddRow = useCallback(() => {
    setQueryResult((prevResult) => {
      if (!Array.isArray(prevResult)) {
        // Ensure prevResult is always an array
        return [{ id: '', name: '', macAddress: '', interface: '' }];
      }
  
      if (prevResult.length === 0) {
        console.log('Table is empty, adding the first row');
        return [{ id: '', name: '', macAddress: '', interface: '' }];
      }
  
      console.log('Table is not empty, adding another row');
      return [
        ...prevResult,
        { id: '', name: '', macAddress: '', interface: '' },
      ];
    });
  }, []);
  
  
  const handleAddBot = async (index) => {
    const row = queryResult[index];

    if (!queryResult[index].botId && !queryResult[index].name && !queryResult[index].macAddress && !queryResult[index].interface) {
      alert('Insert bot info first, idiot!');
      return;
    }

    try {
      const apiUrl = `${BASE_URL}/api/add-bot`;
      await axios.post(apiUrl, {
        originID: originId,
        newBotId: row.id,
        newBotName: row.name,
        newBotMac: row.macAddress,
      });

      alert('Bot added successfully');
    } catch (error) {
      console.error('Failed to add bot:', error);
      alert('Failed to add bot');
    }
  }

  const handleUpdateSounder = async () => {
    try {
      const devices = queryResult
        .filter((item) => item.macAddress && item.macAddress.length >= 5)
        .map((item) => ({
          deviceId: item.id,
          originIp: '127.0.0.1',
          originId: originId,
          macAddress: item.macAddress,
          ifname: item.interface,
        }));
  
      if (devices.length === 0) {
        alert('No valid devices to update. Ensure all MAC addresses are at least 5 characters long.');
        return;
      }
  
      const requestBody = {
        selectedDevices: devices.map((device) => device.macAddress),
        topology: {
          devices: devices,
        },
      };
  
      const apiUrl = `https://trushield-dev-ss.dev.originwireless.us/api/devices/${originId}/network`;
  
      console.log('Request URL:', apiUrl);
      console.log('Request Body:', JSON.stringify(requestBody, null, 2));
  
      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      });
  
      console.log('Response Status:', response.status);
      if (response.status === 200) {
        alert('Sounder updated successfully');
      } else {
        alert(`Update completed with status: ${response.status}`);
      }
      console.log('Response:', response.data);
    } catch (error) {
      console.error('There was an error updating the sounder!', error);
      if (error.response) {
        console.error('Server responded with status code:', error.response.status);
        console.error('Response data:', error.response.data);
        alert(`Failed to update sounder: ${JSON.stringify(error.response.data)}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('Failed to update sounder: No response from server.');
      } else {
        console.error('Error setting up request:', error.message);
        alert(`Failed to update sounder: ${error.message}`);
      }
    }
  };


  const fetchOriginIds = async () => {
    try {
      const apiUrl = `${BASE_URL}/api/get-device-ids`;
      const response = await axios.get(apiUrl);
      console.log('Response Data:', response.data);

      if (Array.isArray(response.data.deviceIds) && response.data.deviceIds.length > 0) {
        const idsArray = response.data.deviceIds[0].split(',');
        setOriginIds(idsArray);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching device IDs:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl"> {/* Enlarged and dynamic container */}
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Update Device</h2>
        <div className="space-y-6">
          <div className="flex justify-center">
            <button
              onClick={handleJwtTokenFetch}
              className="bg-blue-600 text-white py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300"
            >
              Get JWT Token
            </button>
          </div>

          <div>
            <select
              value={originId}
              onClick={fetchOriginIds}
              onChange={(e) => setOriginId(e.target.value)}
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="" disabled>Select Origin ID</option>
              {originIds.map((id, index) => (
                <option key={index} value={id}>{id}</option>
              ))}
            </select>
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleQueryDB}
              className="bg-green-500 text-white py-2 px-6 rounded-full hover:bg-green-600 transition duration-300"
            >
              Query DB
            </button>
          </div>

          {queryResult && (
            <div className="mt-4">
              <h3 className="text-xl font-semibold mb-2 text-center">Query Result:</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-lg shadow-lg">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left bg-gray-200 text-gray-800 font-medium">Bot Id</th>
                      <th className="px-4 py-2 text-left bg-gray-200 text-gray-800 font-medium">Name</th>
                      <th className="px-4 py-2 text-left bg-gray-200 text-gray-800 font-medium">MAC Address</th>
                      <th className="px-4 py-2 text-left bg-gray-200 text-gray-800 font-medium">Interface</th>
                      <th className="px-4 py-2 text-left bg-gray-200 text-gray-800 font-medium">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(queryResult) && queryResult.length > 0 ? (
                      queryResult.map((item, index) => (
                        <tr key={index} className="border-b">
                          <td className="px-4 py-2 text-gray-700">
                            <input
                              type="text"
                              value={item.id}
                              onChange={(e) => handleIdChange(index, e.target.value)}
                              className="w-full px-2 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                            />
                          </td>
                          <td className="px-4 py-2 text-gray-700">
                            <input
                              type="text"
                              value={item.name}
                              onChange={(e) => handleNameChange(index, e.target.value)}
                              className="w-full px-2 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                            />
                          </td>
                          <td className="px-4 py-2 text-gray-700">
                            <input
                              type="text"
                              value={item.macAddress}
                              onChange={(e) => handleMacAddressChange(index, e.target.value)}
                              className="w-full px-2 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                            />
                          </td>
                          <td className="px-4 py-2 text-gray-700">
                            <input
                              type="text"
                              value={item.interface}
                              onChange={(e) => handleInterfaceChange(index, e.target.value)}
                              className="w-full px-2 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                            />
                          </td>
                          <td className="px-4 py-2 text-gray-700">
                            <button
                              onClick={() => handleDeleteRow(index)}
                              className="text-red-600 hover:text-red-800 transition duration-300"
                            >
                              &#x2716;
                            </button>
                            <button
                              onClick={() => handleAddBot(index)}
                              className="text-green-600 hover:text-green-800 transition duration-300"
                            >
                              &#x2714;
                            </button>

{/* 
                            <button
                              onClick={() => handleAddBot(index)}
                              className="text-green-600 hover:text-green-800 transition duration-300"
                            >
                              &#x2714;
                            </button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                          No data available. Please add a row.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-4 flex justify-end">
  <button
    onClick={handleAddRow}
    className="bg-blue-500 text-black px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
  >
    +
  </button>
</div>
            </div>
          )}

          <div className="flex justify-center">
            <button
              onClick={handleUpdateSounder}
              className="bg-blue-600 text-white py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300"
            >
              Update Device
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSounder;